import { Row, Col, Spin, Typography, Table, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import Layout from '../components/Layout';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useApi, QUERY, request } from '../api';
import { Dimension, Stat } from '../types';
import moment from 'moment';
import env from '../env';
import { useState } from 'react';
import { TIMEFRAMES } from '../constants';

/**
 *
 * members
 * by month
 * total
 *
 * organizations
 * by status
 * by month
 * total
 *
 * fileUploads *
 * by month
 * total
 *
 * stories
 * published by month - versions
 * created by month
 * by language
 * total private stories
 * total
 */

const Analytics = () => {
	const [query] = useApi<{
		analytics: {
			events: Stat[];
		};
		mostViewedStories: {
			story: {
				id: string;
				name: string;
				clientStoryId: string;
				createdAt: string;
			};
			views: number;
		}[];
		mostViewedByReferrer: {
			views: number;
			referrer: string;
		}[];
		mostViewedByCountry: {
			views: number;
			country: string;
		}[];
		mostViewedByPlatform: {
			views: number;
			platform: string;
		}[];
	}>({
		query: QUERY.ANALYTICS,
		timeout: 1000,
		variables: {
			timeframe: TIMEFRAMES.TODAY,
		},
	});
	const [timeframeQuery, setTimeframeQuery] = useState<any>();
	const [timeframe, setTimeframe] = useState(TIMEFRAMES.TODAY);

	const reloadTimeframeQuery = async (newTimeframe: string) => {
		const query = await request(
			JSON.stringify({
				query: QUERY.QUERY_BY_TIMEFRAME,
				variables: {
					timeframe: newTimeframe,
				},
			}),
		);

		setTimeframeQuery(query);
		setTimeframe(newTimeframe);
	};

	return (
		<Layout selectedKey="analytics" breadCrumbs={['App', 'Organizations']}>
			{query.loading ? (
				<>
					<Row style={{ paddingTop: 15 }}>
						<Col offset={12}>
							<Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
						</Col>
					</Row>
				</>
			) : (
				<>
					<Row style={{ height: '25vh' }}>
						<Col span="24">
							<ResponsiveContainer width="100%" height="100%">
								<BarChart
									width={500}
									height={300}
									data={query.result?.analytics?.events}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 5,
									}}
								>
									<XAxis
										dataKey="month"
										tickFormatter={(item) => moment(item).format('MMMM YYYY')}
									/>
									<YAxis dataKey="views" />
									<Tooltip />
									<Legend />
									<Bar dataKey="views" name="Views" fill="#8f94fb" />
									<Bar dataKey="uniqueViews" name="Unique" fill="#4e54c8" />
								</BarChart>
							</ResponsiveContainer>
						</Col>
					</Row>
					<Row style={{ height: '25vh' }}>
						<Select
							style={{ width: '25%', marginBottom: 15 }}
							value={timeframe}
							onChange={(val) =>
								reloadTimeframeQuery(val?.toString() || TIMEFRAMES.TODAY)
							}
						>
							{_.map(_.values(TIMEFRAMES), (value) => {
								return (
									<Select.Option value={value}>
										{_.startCase(value)}
									</Select.Option>
								);
							})}
						</Select>
						<Col span="5" style={{ marginRight: 10 }}>
							<Table
								pagination={{
									position: ['bottomCenter'],
									pageSize: 10,
									showSizeChanger: false,
								}}
								size="small"
								columns={[
									{
										align: 'center',
										title: 'Name',
										dataIndex: ['story', 'name'],
										key: 'name',
										render: (_, { story }) => {
											return (
												<a
													target="_blank"
													rel="noopener noreferrer"
													href={`https://${env.app.storiesDomain}/${story?.clientStoryId}`}
												>
													{story?.name}
												</a>
											);
										},
									},
									{
										align: 'center',
										title: 'Views',
										dataIndex: 'views',
										key: 'views',
									},
								]}
								dataSource={
									timeframeQuery?.mostViewedStories ||
									query.result?.mostViewedStories
								}
							/>
						</Col>
						<Col span="5" style={{ marginRight: 10 }}>
							<Table
								pagination={{
									position: ['bottomCenter'],
									pageSize: 10,
									showSizeChanger: false,
								}}
								size="small"
								columns={[
									{
										align: 'center',
										title: 'Referrer',
										dataIndex: 'referrer',
										key: 'referrer',
									},
									{
										align: 'center',
										title: 'Views',
										dataIndex: 'views',
										key: 'views',
									},
								]}
								dataSource={
									timeframeQuery?.mostViewedByReferrer ||
									query.result?.mostViewedByReferrer
								}
							/>
						</Col>
						<Col span="5" style={{ marginRight: 10 }}>
							<Table
								pagination={{
									position: ['bottomCenter'],
									pageSize: 10,
									showSizeChanger: false,
								}}
								size="small"
								columns={[
									{
										align: 'center',
										title: 'Country',
										dataIndex: 'country',
										key: 'country',
										render: (country) => {
											if (!country) return country;

											return (
												<>
													<img
														style={{ marginRight: 5 }}
														width="16"
														alt={country}
														// prettier-ignore
														src={`https://countryflagsapi.com/png/${_.lowerCase(country)}`}
													/>
													{country}
												</>
											);
										},
									},
									{
										align: 'center',
										title: 'Views',
										dataIndex: 'views',
										key: 'views',
									},
								]}
								dataSource={
									timeframeQuery?.mostViewedByCountry ||
									query.result?.mostViewedByCountry
								}
							/>
						</Col>
						<Col span="5" style={{ marginRight: 10 }}>
							<Table
								pagination={{
									position: ['bottomCenter'],
									pageSize: 10,
									showSizeChanger: false,
								}}
								size="small"
								columns={[
									{
										align: 'center',
										title: 'Platform',
										dataIndex: 'platform',
										key: 'platform',
									},
									{
										align: 'center',
										title: 'Views',
										dataIndex: 'views',
										key: 'views',
									},
								]}
								dataSource={
									timeframeQuery?.mostViewedByPlatform ||
									query.result?.mostViewedByPlatform
								}
							/>
						</Col>
					</Row>
				</>
			)}
		</Layout>
	);
};

export default Analytics;
