import _ from 'lodash';
import { ApiError } from './types';

export function numFormatter(num: number | undefined): string {
	if (!num) return '0';

	if (num > 999 && num < 1000000) {
		return (num / 1000).toFixed(1) + 'K';
	} else if (num >= 1000000) {
		return (num / 1000000).toFixed(1) + 'M';
	}

	return String(num);
}

export function praseApiError(e: any): ApiError {
	const defaultErr = {
		id: 'unkown',
		message: 'Encountered unkown error',
		statusCode: 500,
		errorType: 'UnkownException',
	};

	if (e && e.length) {
		const first = _.first(e);

		return {
			id: _.get(first, 'id', defaultErr.id),
			message: _.get(first, 'message', defaultErr.message),
			statusCode: _.get(first, 'statusCode', defaultErr.statusCode),
			errorType: _.get(first, 'errorType', defaultErr.errorType),
		};
	}

	return defaultErr;
}

export function delay(timeout?: number) {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(true);
		}, timeout || 0);
	});
}

export const isSymbol = (node: any) => node?.symbol?.masterId !== undefined;

export const componentWalk = (
	components: any,
	cb: (props: { component: any; index: number; path: string }) => void,
	path: string = '',
) => {
	if (!Array.isArray(components)) {
		return;
	}
	components.forEach((component, index) =>
		cb({
			component,
			index,
			path: path === '' ? `${index}` : `${path}.children.${index}`,
		}),
	);
};
