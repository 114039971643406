import { Alert, Button, Col, Input, Row } from 'antd';
import { useApi, QUERY, login } from '../api';
import { ApiError, User } from '../types';
import { useState } from 'react';
import { praseApiError } from '../utils';

const Login = () => {
	const [error, setError] = useState<ApiError>();
	const [email, setEmail] = useState<string>();
	const [password, setPassword] = useState<string>();
	const [query] = useApi<{ me: User }>({
		query: QUERY.ME,
		timeout: 0,
	});

	if (query.loading) {
		return null;
	}

	if (query.result?.me) {
		window.location.replace('/organization');
		return null;
	}

	const attemptLogin = async () => {
		setError(undefined);

		if (!email || !password) {
			return;
		}

		try {
			const response = await login({
				email,
				password,
			});

			if (response?.login === true) {
				window.location.replace('/organization');
			}
		} catch (e) {
			const error = praseApiError(e);
			setError(error);
		}
	};

	return (
		<>
			<Row style={{ marginTop: 50 }}>
				<Col offset={6} span={12} style={{ display: 'flex' }}>
					<img
						alt="logo"
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
						src="/logo.png"
						width="35%"
					/>
				</Col>
			</Row>
			<Row>
				<Col offset={6} span={12}>
					<Col offset={6} span={12}>
						{error ? (
							<Alert
								style={{ marginBottom: 25 }}
								message={error.message}
								type="error"
								showIcon
							/>
						) : null}
					</Col>
					<Col offset={6} span={12} style={{ marginBottom: 10 }}>
						<Input
							placeholder="Email"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							type="email"
						/>
					</Col>
					<Col offset={6} span={12} style={{ marginBottom: 10 }}>
						<Input
							placeholder="Password"
							onChange={(e) => setPassword(e.target.value)}
							value={password}
							type="password"
						/>
					</Col>
					<Col offset={6} span={12} style={{ display: 'flex', marginBottom: 10 }}>
						<Button
							style={{ marginLeft: 'auto', marginRight: 'auto' }}
							type="primary"
							onClick={attemptLogin}
						>
							Login
						</Button>
					</Col>
				</Col>
			</Row>
		</>
	);
};

export default Login;
