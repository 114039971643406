import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { QUERY, request, useApi } from '../../api';
import Template from '../../components/Template';
import Layout from '../../components/Layout';
import { IStory, ITemplate, Organization } from '../../types';
import { Loader } from '../../components/Loader';

interface MatchParams {
	templateId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const EditTemplate = (props: Props) => {
	const [template] = useApi<{ template?: ITemplate }>({
		query: QUERY.TEMPLATE,
		variables: {
			id: props.match.params.templateId,
		},
		timeout: 1000,
	});
	const [story, setStory] = useState<IStory>();
	const [organization, setOrganization] = useState<Organization>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		async function getTemplateRelations() {
			if (template.result?.template) {
				// consider making it possible to somehow query these two together in one query.
				if (template.result?.template?.organizationId) {
					const { organization } = await request(
						JSON.stringify({
							query: QUERY.ORGANIZATION,
							variables: { id: template.result?.template?.organizationId },
						}),
					);
					setOrganization(organization);
				}

				const { story } = await request(
					JSON.stringify({
						query: QUERY.STORY,
						variables: { id: template.result?.template?.metadata.storyId },
					}),
				);

				setStory(story);
				setIsLoading(false);
			}
		}

		getTemplateRelations();
	}, [template.result?.template]);

	const loading = template.loading || isLoading;
	return (
		<Layout
			selectedKey="templates"
			breadCrumbs={[
				'App',
				'Templates',
				template.result?.template?.name || 'Loading...',
				'Edit',
			]}
		>
			{loading ? (
				<Loader />
			) : (
				<Template
					template={template.result?.template}
					story={story}
					organization={organization}
					cardId={template.result?.template?.metadata.cardId}
					loading={!template.loading && !isLoading}
				/>
			)}
		</Layout>
	);
};

export default EditTemplate;
