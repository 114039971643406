import { Button, Row, Col, Table, Tag, Progress, Tooltip, Spin, Statistic } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import Layout from '../../components/Layout';
import { numFormatter } from '../../utils';
import { useApi, QUERY } from '../../api';
import { Organization } from '../../types';

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'Subscription',
		dataIndex: ['plan', 'name'],
		key: 'planName',
	},
	{
		title: 'Status',
		dataIndex: 'subscriptionStatus',
		key: 'subscriptionStatus',
		render: (status: string) => {
			const canceled = ['CANCEL_ON_RENEWAL', 'CANCELED'];

			if (canceled.includes(status)) {
				return <Tag color="volcano">{status}</Tag>;
			}

			if (status === 'ACTIVE') {
				return <Tag color="green">{status}</Tag>;
			}

			if (status === 'GRACE_PERIOD') {
				return <Tag color="orange">{status}</Tag>;
			}
		},
	},
	{
		title: 'Teams',
		dataIndex: 'teamCount',
		key: 'teamCount',
	},
	{
		title: 'Seats',
		dataIndex: 'seatCount',
		key: 'seatCount',
	},
	{
		title: 'Pageviews',
		dataIndex: ['plan', 'maxPageviews'],
		key: 'pageviews',
		render: (limit: number, row: any) => {
			const currentCounterId = `${row.id}.${moment().format('MMYYYY')}.pageviews`;
			const currentCounter = _.find(
				row.counters,
				(counter: any) => counter.id === currentCounterId,
			);

			if (limit === null) {
				return numFormatter(currentCounter?.count);
			}

			const percent = currentCounter && limit ? (currentCounter.count / limit) * 100 : 0;
			const text =
				currentCounter && limit
					? `${numFormatter(currentCounter.count)} out of ${numFormatter(limit)}`
					: `${currentCounter?.count || 0} Out of ${limit}`;
			return (
				<Tooltip title={text} color="#108ee9">
					<Progress percent={_.floor(percent)} size="small" status="active" />
				</Tooltip>
			);
		},
	},
	{
		title: '',
		key: 'actions',
		render: (_, row) => (
			<>
				<Tooltip title={'Edit'} color="#108ee9">
					<Link to={`/organization/${row.id}/edit`}>
						<Button
							icon={<EditOutlined />}
							style={{
								verticalAlign: 'top',
								marginLeft: 5,
								marginBottom: 5,
							}}
						/>
					</Link>
				</Tooltip>
			</>
		),
	},
];

const OrganizationList = () => {
	const [query] = useApi<{ organizations: Organization[] }>({
		query: QUERY.ORGANIZATIONS,
		timeout: 1000,
	});

	return (
		<Layout selectedKey="organizations" breadCrumbs={['App', 'Organizations']}>
			{query.loading ? (
				<>
					<Row style={{ paddingTop: 15 }}>
						<Col offset={12}>
							<Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
						</Col>
					</Row>
				</>
			) : (
				<>
					<Row justify="end" style={{ paddingBottom: 15 }}>
						<Col flex="auto"></Col>
						<Col>
							<Button type="primary">
								<Link to="/organization/create">New Organization</Link>
							</Button>
						</Col>
					</Row>
					<Row>
						<Col span="24">
							<Table
								columns={columns}
								dataSource={query.result?.organizations}
								style={{ width: '100%' }}
							/>
						</Col>
					</Row>
				</>
			)}
		</Layout>
	);
};

export default OrganizationList;
