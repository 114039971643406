import { Button, Row, Col, Table, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import Layout from '../../components/Layout';
import { useApi, QUERY } from '../../api';
import { SubscriptionPlan } from '../../types';

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		onFilter: (value: string | number | boolean, record: SubscriptionPlan) =>
			record.name.indexOf(String(value)) !== -1,
		filters: [
			{ text: 'Starter', value: 'Starter' },
			{ text: 'Professional', value: 'Professional' },
			{ text: 'Business', value: 'Business' },
		],
		render: (_: any, row: SubscriptionPlan) => {
			return `${row.chargeFrequency} - ${row.name}`;
		},
	},
	{
		title: 'Price',
		dataIndex: 'recurringChargeAmount',
		key: 'recurringChargeAmount',
		render: (recurringChargeAmount: number) => {
			return `$${recurringChargeAmount}`;
		},
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		onFilter: (value: string | number | boolean, record: SubscriptionPlan) =>
			record.status.indexOf(String(value)) !== -1,
		filters: [
			{ text: 'Active', value: 'ACTIVE' },
			{ text: 'Suspended', value: 'SUSPENDED' },
		],
	},
	{
		title: 'Trial Period',
		dataIndex: 'trialPeriodDays',
		key: 'trialPeriodDays',
	},
	{
		title: 'Grace Period',
		dataIndex: 'gracePeriodDays',
		key: 'gracePeriodDays',
	},
];

const PlanList = () => {
	const [query] = useApi<{ subscriptionPlans: SubscriptionPlan[] }>({
		query: QUERY.SUBSCRIPTION_PLANS,
		timeout: 1000,
	});

	return (
		<Layout selectedKey="subscriptions" breadCrumbs={['App', 'Subscription Plans']}>
			{query.loading ? (
				<>
					<Row style={{ paddingTop: 15 }}>
						<Col offset={12}>
							<Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
						</Col>
					</Row>
				</>
			) : (
				<>
					<Row justify="end" style={{ paddingBottom: 15 }}>
						<Col flex="auto"></Col>
						<Col>
							<Button type="primary">
								<Link to="/plan/create">New Plan</Link>
							</Button>
						</Col>
					</Row>
					<Row>
						<Col span="24">
							<Table
								columns={columns}
								dataSource={query.result?.subscriptionPlans}
								style={{ width: '100%' }}
							/>
						</Col>
					</Row>
				</>
			)}
		</Layout>
	);
};

export default PlanList;
