import { Form, Input, Typography, Checkbox, Row, InputNumber } from 'antd';
import _ from 'lodash';
import { numFormatter } from '../utils';

export const Organization = (props: {
	unlimited: { [key: string]: boolean };
	setUnlimited: Function;
}) => {
	const features = [
		'teamsEnabled',
		'teamRoles',
		'marketingPixels',
		'customCSS',
		'customFonts',
		'googleAnalytics',
	];
	return (
		<>
			<Form.Item
				label="Name"
				name="name"
				rules={[{ required: true, message: 'Name is required.' }]}
			>
				<Input />
			</Form.Item>
			<Typography.Text
				style={{ display: 'inline-block', lineHeight: '28px' }}
				type="secondary"
			>
				https://stories.sc/
			</Typography.Text>
			<Form.Item
				name="customId"
				style={{ display: 'inline-block' }}
				wrapperCol={{ span: 24 }}
				rules={[{ required: true, message: 'Custom Id is required.' }]}
			>
				<Input style={{ marginLeft: 5 }} />
			</Form.Item>

			<Row>
				<Form.Item name={['plan', 'maxPageviews']} label="Views">
					<InputNumber
						disabled={props.unlimited.pageviews}
						formatter={numFormatter}
						step={1000000}
					/>
				</Form.Item>
				<Checkbox
					checked={props.unlimited.pageviews}
					style={{ lineHeight: '32px', marginLeft: 10 }}
					onChange={(e) =>
						props.setUnlimited({
							...props.unlimited,
							pageviews: !props.unlimited.pageviews,
						})
					}
				>
					Unlimited Views
				</Checkbox>
			</Row>

			<Row>
				<Form.Item name={['plan', 'maxTeams']} label="Teams">
					<InputNumber disabled={props.unlimited.teams} formatter={numFormatter} />
				</Form.Item>
				<Checkbox
					checked={props.unlimited.teams}
					style={{ lineHeight: '32px', marginLeft: 10 }}
					onChange={(e) =>
						props.setUnlimited({
							...props.unlimited,
							teams: !props.unlimited.teams,
						})
					}
				>
					Unlimited Teams
				</Checkbox>
			</Row>

			<Row>
				<Form.Item name={['plan', 'memberSeats']} label="Seats">
					<InputNumber disabled={props.unlimited.seats} formatter={numFormatter} />
				</Form.Item>
				<Checkbox
					checked={props.unlimited.seats}
					style={{ lineHeight: '32px', marginLeft: 10 }}
					onChange={(e) =>
						props.setUnlimited({
							...props.unlimited,
							seats: !props.unlimited.seats,
						})
					}
				>
					Unlimited Seats
				</Checkbox>
			</Row>

			<Row>
				<Form.Item name={['plan', 'maxStories']} label="Stories">
					<InputNumber disabled={props.unlimited.stories} formatter={numFormatter} />
				</Form.Item>
				<Checkbox
					checked={props.unlimited.stories}
					style={{ lineHeight: '32px', marginLeft: 10 }}
					onChange={(e) =>
						props.setUnlimited({
							...props.unlimited,
							stories: !props.unlimited.stories,
						})
					}
				>
					Unlimited Stories
				</Checkbox>
			</Row>

			{_.map(features, (checkbox) => (
				<Form.Item
					wrapperCol={{ span: 24 }}
					style={{ display: 'inline-block', paddingRight: 10 }}
					valuePropName="checked"
					name={['plan', checkbox]}
				>
					<Checkbox key={checkbox} style={{ lineHeight: '32px' }}>
						{_.startCase(checkbox)}
					</Checkbox>
				</Form.Item>
			))}
		</>
	);
};
