export const TEMPLATE_LANGUAGE = {
	ENGLISH: 'EN',
	HEBREW: 'HE',
	RUSSIAN: 'RU',
};

export const CARD_TYPE = {
	INFO: 'INFO',
	TRIVIA: 'TRIVIA',
	POLL: 'POLL',
	PERSONALITY_TEST: 'PERSONALITY_TEST',
	TRUE_OR_FALSE: 'TRUE_OR_FALSE',
	THIS_OR_THAT: 'THIS_OR_THAT',
	FORM: 'FORM',
	REGISTRATION: 'REGISTRATION',
};

export const TEMPLATE_CATEGORIES = {
	INTENTION: {
		COLLECT_DATA: 'Collect data',
		COLLECT_LEADS: 'Collect leads',
		INCREASE_TIME_ON_PAGE: 'Increase time on page',
		ENGAGE_USERS: 'Engage users',
		CREATE_SEGMENTS: 'Create segments',
		MARKETING_MESSAGES: 'Communicating marketing messages',
		SUMMARY_BY_SCORE: 'Summary by score',
		SAY_SOMETHING: 'Say something',
	},
};

export const TEMPLATE_TYPE = {
	STORY: 'story',
	CARD: 'card',
};

export const TIMEFRAMES = {
	TODAY: 'today',
	THISWEEK: 'thisWeek',
	THISMONTH: 'thisMonth',
	LAST7DAYS: 'last7Days',
	LAST30DAYS: 'last30Days',
	LAST3MONTHS: 'last3Months',
};

export const HOSTING_PATH = {
	'prod-storycards-gallery-imgix': '//storycards.imgix.net',
	'prod-storycards-gallery-assets': '//assts.stories.sc',
	'dev-low-cost-galleryimgixbucket349e0b82-cab4l3150sim':
		'//dev-low-cost-galleryimgixbucket349e0b82-cab4l3150sim.s3.eu-west-1.amazonaws.com',
	'dev-low-cost-galleryassetsbucket9eb44ac2-pkzruw7z30pc':
		'//dev-low-cost-galleryassetsbucket9eb44ac2-pkzruw7z30pc.s3.eu-west-1.amazonaws.com',
	'gallery-imgix': '//localhost:4566/gallery-imgix',
	'gallery-assets': '//localhost:4566/gallery-assets',
};
