import { Button, Form, Select, InputNumber, Alert, message } from 'antd';
import { useState } from 'react';
import Layout from '../../components/Layout';
import { delay, praseApiError } from '../../utils';
import { createSubscriptionPlan } from '../../api';
import { ApiError } from '../../types';

const initialForm = {
	status: 'ACTIVE',
	name: 'Starter',
	chargeFrequency: 'MONTHLY',
	trialPeriodDays: 7,
	recurringChargeAmount: 100,
	gracePeriodDays: 7,
};

const PlanCreate = () => {
	const [error, setError] = useState<ApiError | null>(null);

	const onFinish = async (values: any) => {
		setError(null);
		message.loading({ content: 'Creating...', key: 'creation' });

		try {
			await createSubscriptionPlan(values);
			message.success({ content: 'Successfuly created, redirecting...', key: 'creation' });
			await delay(1000);

			window.location.assign('/plan');
		} catch (e) {
			message.destroy('creation');
			const error = praseApiError(e);
			setError(error);
		}
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};
	console.log(error);
	return (
		<Layout selectedKey="subscriptions" breadCrumbs={['App', 'Subscription Plans', 'Create']}>
			{error ? (
				<Alert style={{ marginBottom: 25 }} message={error.message} type="error" showIcon />
			) : null}
			<Form
				name="basic"
				wrapperCol={{ span: 6 }}
				initialValues={initialForm}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					label="Status"
					name="status"
					rules={[{ required: true, message: 'Status is required.' }]}
				>
					<Select defaultValue="ACTIVE">
						<Select.Option value="ACTIVE">Active</Select.Option>
						<Select.Option value="SUSPENDED">Suspended</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="Name"
					name="name"
					rules={[{ required: true, message: 'Name is required.' }]}
				>
					<Select defaultValue="Starter">
						<Select.Option value="Starter">Starter</Select.Option>
						<Select.Option value="Professional">Professional</Select.Option>
						<Select.Option value="Business">Business</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="Charge Frequency"
					name="chargeFrequency"
					rules={[{ required: true, message: 'Charge Frequency is required.' }]}
				>
					<Select defaultValue="MONTHLY">
						<Select.Option value="MONTHLY">Monthly</Select.Option>
						<Select.Option value="ANNUALLY">Annually</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="Price"
					name="recurringChargeAmount"
					rules={[{ required: true, message: 'Price is required.' }]}
				>
					<InputNumber step={100} />
				</Form.Item>
				<Form.Item
					label="Trial Period"
					name="trialPeriodDays"
					rules={[{ required: true, message: 'Trial period is required.' }]}
				>
					<InputNumber />
				</Form.Item>

				<Form.Item
					label="Grace Period"
					name="gracePeriodDays"
					rules={[{ required: true, message: 'Grace period is required.' }]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item style={{ marginTop: 5 }}>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Layout>
	);
};

export default PlanCreate;
