import _ from 'lodash';
import React from 'react';
import env from './env';
import { OrganizationPlan } from './types';
import { delay } from './utils';

export const QUERY = {
	TEMPLATES: `{
		templates {
			id
			organizationId
			name
			description
			metadata
			type
			categories
			isPublic
			language
			subtitle
			hoverImageFile {
				hosting
				filepath
			}
			coverImageFile {
				hosting
				filepath
			}
			createdAt
			updatedAt
		}
	}`,
	TEMPLATE: `
query ($id: String!) {
	template(id: $id) {
		id
		organizationId
		name
		description
		metadata
		type
		categories
		isPublic
		language
		subtitle
		hoverImageFile {
			hosting
			filepath
		}
		coverImageFile {
			hosting
			filepath
		}
		createdAt
		updatedAt
	}
}
	`,
	ORGANIZATION: `
query ($id: String!) {
	organization(id: $id) {
		id
		name
		customId
		subscriptionStatus
		seatCount
		storyCount
		teamCount
		plan {
			name
			maxPageviews
			memberSeats
			maxTeams
			maxStories
			teamsEnabled
			teamRoles
			marketingPixels
			customCSS
			customFonts
			googleAnalytics
		}
		counters {
			id
			type
			count
		}
	}
}
	`,
	STORY: `
query ($id: String!) {
  story(id: $id) {
    id
	clientStoryId
	language
    storyVersions {
      published {
        version
        settings
		data
      }
    }
  }
}
	`,
	ORGANIZATIONS: `{
		organizations {
			id
			name
			subscriptionStatus
			seatCount
			storyCount
			teamCount
			plan {
				name
				maxPageviews
			}
			counters {
				id
				type
				count
			}
		}
	}`,
	SUBSCRIPTION_PLANS: `{
		subscriptionPlans {
			id
			name
			status
			trialPeriodDays
			recurringChargeAmount
			chargeFrequency
			gracePeriodDays
		}
	}`,
	ME: `{
		me {
			id
			name
			email
		}
	}`,
	QUERY_BY_TIMEFRAME: `
query ($timeframe: String!) {
	mostViewedStories(timeframe: $timeframe) {
		views
		story {
			id
			name
			clientStoryId
			createdAt
		}
	}
	mostViewedByReferrer(timeframe: $timeframe) {
		views
		referrer
	}
	mostViewedByCountry(timeframe: $timeframe) {
		views
		country
	}
	mostViewedByPlatform(timeframe: $timeframe) {
		views
		platform
	}
}
	`,
	ANALYTICS: `
query ($timeframe: String!) {
	mostViewedStories(timeframe: $timeframe) {
		views
		story {
			id
			name
			clientStoryId
			createdAt
		}
	}
	mostViewedByReferrer(timeframe: $timeframe) {
		views
		referrer
	}
	mostViewedByCountry(timeframe: $timeframe) {
		views
		country
	}
	mostViewedByPlatform(timeframe: $timeframe) {
		views
		platform
	}
	analytics {
		events {
			month
			views
			uniqueViews
			avgPlaytime
		}
	}
}
`,
};

interface ApiRequestState<T> {
	loading: boolean;
	result?: T | null;
}

export type onFinish<T> = (result: T) => any;

export function useApi<T>(params: {
	query: string;
	variables?: any;
	timeout?: number;
	onFinish?: onFinish<T>;
}): [ApiRequestState<T>, React.Dispatch<React.SetStateAction<ApiRequestState<T>>>] {
	const [state, setState] = React.useState<ApiRequestState<T>>({
		loading: true,
	});

	React.useEffect(() => {
		async function request() {
			const result = await query<T>(params.query, params.variables);

			setTimeout(() => {
				setState({
					loading: false,
					result,
				});

				if (params.onFinish) {
					params.onFinish(result);
				}
			}, params.timeout || 0);
		}

		request();
	}, []);

	return [state, setState];
}

export function query<T>(query: string, variables?: any): Promise<T> {
	return request(JSON.stringify({ query, variables }));
}

export function createSubscriptionPlan(params: {
	name: string;
	chargeFrequency: string;
	status: string;
	trialPeriodDays: number;
	recurringChargeAmount: number;
	gracePeriodDays: number;
}): Promise<any> {
	const body = JSON.stringify({
		query: `
mutation ($input: SubscriptionPlanInput!) {
	createSubscriptionPlan(
		input: $input
	) { id }
}`,
		variables: {
			input: {
				name: params.name,
				status: params.status,
				chargeFrequency: params.chargeFrequency,
				trialPeriodDays: params.trialPeriodDays,
				recurringChargeAmount: params.recurringChargeAmount,
				gracePeriodDays: params.gracePeriodDays,
				currency: 'USD',
			},
		},
	});

	return request(body, 500);
}

export function createOrganization(params: {
	name: string;
	customId: string;
	emails: string[];
	plan: OrganizationPlan;
}): Promise<any> {
	const body = JSON.stringify({
		query: `
mutation ($input: OrganizationInput!) {
	createOrganization(
		input: $input
	) { id }
}`,
		variables: {
			input: {
				name: params.name,
				customId: params.customId,
				plan: params.plan,
				emails: params.emails,
			},
		},
	});

	return request(body);
}

export function updateOrganization(params: {
	id: string;
	name: string;
	customId: string;
	plan: OrganizationPlan;
}): Promise<any> {
	const body = JSON.stringify({
		query: `
mutation ($id: String!, $input: OrganizationInput!) {
	updateOrganization(
		id: $id,
		input: $input
	) { id }
}`,
		variables: {
			id: params.id,
			input: {
				name: params.name,
				customId: params.customId,
				plan: params.plan,
			},
		},
	});

	return request(body);
}

export function createTemplate(params: {
	name: string;
	subtitle: string;
	description: string;
	editor: any;
	metadata: any;
	type: string;
	categories: string[];
	language: string;
	isPublic: boolean;
	organizationId?: string;
}): Promise<any> {
	const body = JSON.stringify({
		query: `
mutation ($input: TemplateInput!) {
	createTemplate(
		input: $input
	) { id }
}`,
		variables: {
			input: params,
		},
	});

	return request(body);
}

export function updateTemplate(params: {
	templateId: string;
	name: string;
	subtitle: string;
	description: string;
	editor: any;
	metadata: any;
	type: string;
	categories: string[];
	language: string;
	isPublic: boolean;
	organizationId?: string;
}): Promise<any> {
	const body = JSON.stringify({
		query: `
mutation ($id: String!, $input: TemplateInput!) {
	updateTemplate(
		id: $id
		input: $input
	) { id }
}`,
		variables: {
			id: params.templateId,
			input: _.omit(params, ['templateId']),
		},
	});

	return request(body);
}

export function login(params: { email: string; password: string }): Promise<any> {
	const body = JSON.stringify({
		query: `
mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password)
}`,
		variables: {
			email: params.email,
			password: params.password,
		},
	});

	return request(body);
}

export function uploadTemplateCoverImage(params: {
	templateId: string;
	data: string;
}): Promise<any> {
	const body = JSON.stringify({
		query: `
mutation updateTemplateCoverImage($id: String!, $input: TemplateImageInput!) {
  updateTemplateCoverImage(id: $id, input: $input) {
	  hosting
	  filepath
  }
}`,
		variables: {
			id: params.templateId,
			input: {
				data: params.data,
			},
		},
	});

	return request(body);
}

export function uploadTemplateHoverImage(params: {
	templateId: string;
	data: string;
}): Promise<any> {
	const body = JSON.stringify({
		query: `
mutation updateTemplateHoverImage($id: String!, $input: TemplateImageInput!) {
  updateTemplateHoverImage(id: $id, input: $input) {
	  hosting
	  filepath
  }
}`,
		variables: {
			id: params.templateId,
			input: {
				data: params.data,
			},
		},
	});

	return request(body);
}

export function deleteTemplateCoverImage(params: { templateId: string }): Promise<any> {
	const body = JSON.stringify({
		query: `
mutation deleteTemplateCoverImage($id: String!) {
  deleteTemplateCoverImage(id: $id)
}`,
		variables: {
			id: params.templateId,
		},
	});

	return request(body);
}

export function deleteTemplateHoverImage(params: { templateId: string }): Promise<any> {
	const body = JSON.stringify({
		query: `
mutation deleteTemplateHoverImage($id: String!) {
  deleteTemplateHoverImage(id: $id)
}`,
		variables: {
			id: params.templateId,
		},
	});

	return request(body);
}

export function request(body: any, timeout?: number) {
	return fetch(`//${env.app.apiDomain}`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body,
		credentials: 'include',
	})
		.then((res) => res.json())
		.then(async (json) => {
			await delay(timeout);
			return json;
		})
		.then((json) => {
			if (json.errors && json.errors.length) {
				return Promise.reject(json.errors);
			}

			return json.data;
		});
}
