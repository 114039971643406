/**
 * customId
 * subscriptionStatus
 *

 *
 * plan overrides
 * teamsEnabled
 * maxTeams
 * teamRoles
 *
 * memberSeats
 * marketingPixels
 * customCSS
 * customFonts
 * googleAnalytics
 */

import { Button, Form, Input, Typography, Tag, Checkbox, Row, Col, InputNumber } from 'antd';
import { useState } from 'react';
import _ from 'lodash';
import Layout from '../../components/Layout';
import { numFormatter } from '../../utils';
import { createOrganization } from '../../api';

interface Props {
	// form?: {
	// 	name: string;
	// 	customId: string;
	// 	subscriptionStatus: string;
	// 	planCheckbox: string[];
	// 	plan: {
	// 		maxPageviews?: number;
	// 		maxTeams?: number;
	// 		maxStories?: number;
	// 		memberSeats?: number;
	// 		teamsEnabled?: boolean;
	// 		teamRoles?: boolean;
	// 		marketingPixels?: boolean;
	// 		customCSS?: boolean;
	// 		customFonts?: boolean;
	// 		googleAnalytics?: boolean;
	// 	};
	// };
	form?: any;
	isEditExisting: boolean;
}

const initialForm = {
	name: 'Demo Organization',
	customId: 'demo_org',
	subscriptionStatus: 'ACTIVE',
	planCheckbox: [
		'teamsEnabled',
		'teamRoles',
		'marketingPixels',
		'customCSS',
		'customFonts',
		'googleAnalytics',
	],
	plan: {
		maxPageviews: 1000000,
		maxTeams: null,
		maxStories: null,
		memberSeats: null,
		teamsEnabled: true,
		teamRoles: true,
		marketingPixels: true,
		customCSS: true,
		customFonts: true,
		googleAnalytics: true,
	},
};

const CreateOrganization = (props: Props) => {
	const [invites, setInvites] = useState<string[]>(['vadim@rabbi.co.il']);
	const [invite, setInvite] = useState<string>('');
	const [isUnlimitedSeats, setIsUnlimitedSeats] = useState<boolean>(true);
	const [isUnlimitedStories, setIsUnlimitedStories] = useState<boolean>(true);
	const [isUnlimitedTeams, setIsUnlimitedTeams] = useState<boolean>(true);
	const [isUnlimitedPageviews, setIsUnlimitedPageviews] = useState<boolean>(false);

	console.log(props.form);
	const onFinish = (values: any) => {
		if (!invites.length) {
			alert('Add emails to invite list');
			return;
		}

		values.planCheckbox.forEach((key: string, value: string) => {
			values.plan[key] = true;
		});

		if (isUnlimitedPageviews) {
			values.plan.maxPageviews = null;
		}

		if (isUnlimitedSeats) {
			values.plan.memberSeats = null;
		}

		if (isUnlimitedStories) {
			values.plan.maxStories = null;
		}

		if (isUnlimitedTeams) {
			values.plan.maxTeams = null;
		}

		console.log('Success:', values);
		createOrganization({
			...values,
			emails: invites,
		});
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Layout selectedKey="organizations" breadCrumbs={['App', 'Organizations', 'Create']}>
			<Form
				name="basic"
				wrapperCol={{ span: 6 }}
				initialValues={props.form || initialForm}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					label="Name"
					name="name"
					rules={[{ required: true, message: 'Name is required.' }]}
				>
					<Input />
				</Form.Item>
				<Typography.Text
					style={{ display: 'inline-block', lineHeight: '28px' }}
					type="secondary"
				>
					https://stories.sc/
				</Typography.Text>
				<Form.Item
					// label="Custom Id"
					name="customId"
					style={{ display: 'inline-block' }}
					wrapperCol={{ span: 24 }}
					rules={[{ required: true, message: 'Custom Id is required.' }]}
				>
					<Input style={{ marginLeft: 5 }} />
				</Form.Item>

				<Row>
					<Form.Item name={['plan', 'maxPageviews']} label="Views">
						<InputNumber
							disabled={isUnlimitedPageviews}
							formatter={numFormatter}
							step={1000000}
						/>
					</Form.Item>
					<Checkbox
						checked={isUnlimitedPageviews}
						style={{ lineHeight: '32px', marginLeft: 10 }}
						onChange={(e) => setIsUnlimitedPageviews(!isUnlimitedPageviews)}
					>
						Unlimited Views
					</Checkbox>
				</Row>

				<Row>
					<Form.Item name={['plan', 'memberSeats']} label="Seats">
						<InputNumber disabled={isUnlimitedSeats} formatter={numFormatter} />
					</Form.Item>
					<Checkbox
						checked={isUnlimitedSeats}
						style={{ lineHeight: '32px', marginLeft: 10 }}
						onChange={(e) => setIsUnlimitedSeats(!isUnlimitedSeats)}
					>
						Unlimited Seats
					</Checkbox>
				</Row>

				<Row>
					<Form.Item name={['plan', 'maxTeams']} label="Teams">
						<InputNumber disabled={isUnlimitedTeams} formatter={numFormatter} />
					</Form.Item>
					<Checkbox
						checked={isUnlimitedTeams}
						style={{ lineHeight: '32px', marginLeft: 10 }}
						onChange={(e) => setIsUnlimitedTeams(!isUnlimitedTeams)}
					>
						Unlimited Teams
					</Checkbox>
				</Row>

				<Row>
					<Form.Item name={['plan', 'maxStories']} label="Stories">
						<InputNumber disabled={isUnlimitedStories} formatter={numFormatter} />
					</Form.Item>
					<Checkbox
						checked={isUnlimitedStories}
						style={{ lineHeight: '32px', marginLeft: 10 }}
						onChange={(e) => setIsUnlimitedStories(!isUnlimitedStories)}
					>
						Unlimited Stories
					</Checkbox>
				</Row>

				<Form.Item wrapperCol={{ span: 18 }} name="planCheckbox" label="Features">
					<Checkbox.Group style={{ width: '100%' }}>
						<Row>
							{_.map(initialForm.planCheckbox, (checkbox) => {
								return (
									<Col span={3}>
										<Checkbox
											key={checkbox}
											value={checkbox}
											style={{ lineHeight: '32px' }}
										>
											{_.startCase(checkbox)}
										</Checkbox>
									</Col>
								);
							})}
						</Row>
					</Checkbox.Group>
				</Form.Item>
				<Row>
					<Col span={6}>
						<Input value={invite} onChange={(e) => setInvite(e.target.value)} />
					</Col>
					<Button
						onClick={() => {
							setInvites([...invites, invite]);
							setInvite('');
						}}
					>
						Add Invite
					</Button>
				</Row>
				<Row style={{ marginTop: 5 }}>
					{_.map(invites, (invite, index) => (
						<Tag
							closable
							onClose={() => {
								setInvites(invites.splice(index, 1));
							}}
							color="#108ee9"
						>
							{invite}
						</Tag>
					))}
				</Row>

				<Form.Item style={{ marginTop: 5 }}>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Layout>
	);
};

export default CreateOrganization;
