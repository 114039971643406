import Layout from '../../components/Layout';
import Template from '../../components/Template';

const TemplateCreate = () => {
	return (
		<Layout selectedKey="templates" breadCrumbs={['App', 'Templates', 'Create']}>
			<Template />
		</Layout>
	);
};

export default TemplateCreate;
