import { Layout as AntdLayout, Menu, Breadcrumb } from 'antd';
import { UserOutlined, AreaChartOutlined, SyncOutlined, CopyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Header, Content, Footer, Sider } = AntdLayout;

const Layout = (props: {
	children?: React.ReactNode;
	selectedKey: string;
	breadCrumbs: string[];
}) => {
	return (
		<AntdLayout style={{ minHeight: '100vh' }}>
			<Header className="header">{/* <div className="logo" /> */}</Header>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					{props.breadCrumbs.map((crumb) => (
						<Breadcrumb.Item>{crumb}</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<AntdLayout
					className="site-layout-background"
					style={{ padding: '24px 0', height: '75vh' }}
				>
					<Sider className="site-layout-background" width={200}>
						<Menu
							mode="inline"
							defaultSelectedKeys={[props.selectedKey]}
							style={{ height: '100%' }}
						>
							<Menu.Item icon={<UserOutlined />} key="organizations">
								<Link to="/organization">Organizations</Link>
							</Menu.Item>
							<Menu.Item icon={<AreaChartOutlined />} key="analytics">
								<Link to="/analytics">Analytics</Link>
							</Menu.Item>
							<Menu.Item icon={<SyncOutlined />} key="subscriptions">
								<Link to="/plan">Subscription Plans</Link>
							</Menu.Item>
							<Menu.Item icon={<CopyOutlined />} key="templates">
								<Link to="/template">Templates</Link>
							</Menu.Item>
						</Menu>
					</Sider>
					<Content style={{ padding: '0 24px', minHeight: 280 }}>
						{props.children}
					</Content>
				</AntdLayout>
			</Content>
			<Footer style={{ textAlign: 'center' }}>Storycards ©2021</Footer>
		</AntdLayout>
	);
};

export default Layout;
