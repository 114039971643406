import './App.css';
import 'react-quill/dist/quill.snow.css';

import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { UpdateOrganization } from './pages/organization/Update';
import { User } from './types';
import { QUERY, useApi } from './api';

import OrganizationList from './pages/organization/List';
import CreateOrganization from './pages/organization/Create';
import Analytics from './pages/Analytics';
import PlanList from './pages/plan/List';
import NotFound from './pages/NotFound';
import PlanCreate from './pages/plan/Create';
import Login from './pages/Login';
import TemplateCreate from './pages/template/Create';
import TemplateList from './pages/template/List';
import TemplateEdit from './pages/template/Edit';

const App = () => {
	const [query] = useApi<{ me: User }>({
		query: QUERY.ME,
		timeout: 0,
	});

	if (query.loading) {
		return null;
	}

	const isLoggedIn = query.result?.me;
	if (!isLoggedIn && window.location.pathname !== '/login') {
		window.location.replace('/login');
		return null;
	}

	if (!isLoggedIn) {
		return (
			<div className="App">
				<BrowserRouter>
					<Switch>
						<Route exact path="/login" component={Login} />
					</Switch>
				</BrowserRouter>
			</div>
		);
	}

	return (
		<div className="App">
			<BrowserRouter>
				<Switch>
					<Route exact path="/login" component={Login} />
					<Route exact path="/analytics" component={Analytics} />
					<Route exact path="/template" component={TemplateList} />
					<Route exact path="/template/create" component={TemplateCreate} />
					<Route exact path="/template/:templateId/edit" component={TemplateEdit} />
					<Route exact path="/plan" component={PlanList} />
					<Route exact path="/plan/create" component={PlanCreate} />
					<Route exact path="/organization" component={OrganizationList} />
					<Route exact path="/organization/create" component={CreateOrganization} />
					<Route
						exact
						path="/organization/:organizationId/edit"
						component={UpdateOrganization}
					/>
					<Route component={NotFound} />
				</Switch>
			</BrowserRouter>
		</div>
	);
};

export default App;
