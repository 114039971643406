import {
	Button,
	Table,
	message,
	Tag,
	Image,
	Upload,
	Row,
	Col,
	Modal,
	Tooltip,
	Input,
	Space,
} from 'antd';
import {
	UploadOutlined,
	CloseOutlined,
	EditOutlined,
	SearchOutlined,
	PlayCircleOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import Layout from '../../components/Layout';
import {
	deleteTemplateHoverImage,
	deleteTemplateCoverImage,
	QUERY,
	uploadTemplateCoverImage,
	uploadTemplateHoverImage,
	useApi,
} from '../../api';
import { ITemplate } from '../../types';
import { CARD_TYPE, HOSTING_PATH, TEMPLATE_CATEGORIES } from '../../constants';
import { Loader } from '../../components/Loader';
import moment from 'moment';

const columns = [
	{
		title: 'Title',
		dataIndex: 'subtitle',
		key: 'subtitle',
	},
	{
		title: 'Language',
		dataIndex: 'language',
		key: 'language',
		filters: [
			{
				text: 'English',
				value: 'EN',
			},
			{
				text: 'עברית',
				value: 'HE',
			},
			{
				text: 'Русский',
				value: 'RU',
			},
		],
		onFilter: (value, story) => story.language === value,
	},
	{
		title: 'Public',
		dataIndex: 'isPublic',
		key: 'isPublic',
		render: (isPublic) =>
			isPublic ? <Tag color="#87d068">Public</Tag> : <Tag color="#f50">Private</Tag>,
		filters: [
			{
				text: 'Private',
				value: false,
			},
			{
				text: 'Public',
				value: true,
			},
		],
		onFilter: (value, story) => story.isPublic === value,
	},
	{
		title: 'Organization',
		dataIndex: 'organizationId',
		key: 'organizationId',
	},
	{
		title: 'Categories',
		dataIndex: 'categories',
		key: 'categories',
		render: (categories) =>
			_.map(categories, (category) => (
				<Tag color="#87d068" key={category}>
					{category}
				</Tag>
			)),
		filters: [
			..._.map(CARD_TYPE, (type) => ({ text: _.startCase(_.lowerCase(type)), value: type })),
			..._.map(TEMPLATE_CATEGORIES.INTENTION, (value, intention) => ({
				text: _.startCase(_.lowerCase(intention)),
				value: intention,
			})),
		],
		onFilter: (value, story) => story.categories.includes(value),
	},
];

const TemplateList = () => {
	const [query] = useApi<{ templates: ITemplate[] }>({
		query: QUERY.TEMPLATES,
		timeout: 1000,
	});

	const [description, setDescription] = useState();
	const [uploads, setUpload] = useState<any>({});
	const [searchText, setSearchText] = useState<string>();
	const [searchColumn, setSearchColumn] = useState<string>();

	const descriptionColumn = {
		title: 'Description',
		dataIndex: 'description',
		key: 'description',
		render: (description) => {
			return (
				<Button type="primary" onClick={() => setDescription(description)}>
					Show
				</Button>
			);
		},
	};

	const getSearchableColumn = (dataIndex: string) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => {
						confirm();
						setSearchText(selectedKeys[0]);
						setSearchColumn(dataIndex);
					}}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => {
							confirm();
							setSearchText(selectedKeys[0]);
							setSearchColumn(dataIndex);
						}}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => {
							clearFilters();
							setSearchText('');
							setSearchColumn(undefined);
						}}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm();
							setSearchText(selectedKeys[0]);
							setSearchColumn(dataIndex);
						}}
					>
						Filter
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
		),
		onFilter: (value, record) => {
			return record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '';
		},

		render: (text) => text,
	});

	const imageColumn = (image: 'cover' | 'hover') => {
		const vars = {
			hover: {
				key: 'hoverImage',
				title: 'Hover',
				update: uploadTemplateHoverImage,
				delete: deleteTemplateHoverImage,
				responseKey: 'updateTemplateHoverImage',
			},
			cover: {
				key: 'coverImage',
				title: 'Cover',
				update: uploadTemplateCoverImage,
				delete: deleteTemplateCoverImage,
				responseKey: 'updateTemplateCoverImage',
			},
		};
		const config = vars[image];

		return {
			title: config.title,
			dataIndex: config.key,
			key: config.key,
			render: (item, row) => {
				const uploadId = `${row.id}-${config.key}`;
				const props = {
					customRequest: (params) => {
						const { file, onSuccess } = params;
						const reader = new FileReader();

						reader.onload = async () => {
							if (!reader.result) return;

							const { [config.responseKey]: response } = await config.update({
								templateId: row.id,
								data: reader.result?.toString(),
							});
							setUpload({
								...uploads,
								[uploadId]: response,
							});
							onSuccess();
						};
						reader.readAsDataURL(file);
					},
					beforeUpload: (file) => {
						const isSupported =
							file.type.startsWith('image') || file.type.startsWith('video');
						if (!isSupported) {
							message.error(`${file.type} is not supported.`);
						}

						const isLt2M = file.size / 1024 / 1024 < 2.5;
						if (!isLt2M) {
							message.error('Upload must smaller than 2.5MB!');
						}

						return isSupported ? true : Upload.LIST_IGNORE;
					},
					maxCount: 1,
				};

				const upload = uploads[uploadId] || row[`${config.key}File`];

				const UploadComponent = () => (
					<Upload listType="picture" {...props}>
						<Button
							icon={<UploadOutlined />}
							style={{
								verticalAlign: 'top',
								marginLeft: 5,
								marginBottom: 5,
							}}
						/>
					</Upload>
				);

				if (upload && !upload.canceled) {
					const file = `${HOSTING_PATH[upload.hosting]}/${upload.filepath}`;
					const ext = _.last(_.split(upload.filepath, '.'));
					const isVideo = ['mp4', 'webm'].includes(ext || '');

					return (
						<Row style={{ width: 160, display: 'flex' }}>
							{isVideo ? (
								<Col
									style={{
										marginLeft: 'auto',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Tooltip title={`View ${config.title} Video`} color="#108ee9">
										<Link rel="noopener noreferrer" target="_blank" to={file}>
											<Button
												icon={<PlayCircleOutlined />}
												style={{ verticalAlign: 'top' }}
											/>
										</Link>
									</Tooltip>
								</Col>
							) : (
								<Col style={{ marginLeft: 'auto' }}>
									<Image src={file} width={100} />
								</Col>
							)}
							<Col>
								<Row>
									<UploadComponent />
								</Row>
								<Row>
									<Button
										onClick={() =>
											config.delete({ templateId: row.id }).then(() => {
												setUpload({
													...uploads,
													[uploadId]: {
														...upload,
														canceled: true,
													},
												});
											})
										}
										icon={<CloseOutlined />}
										style={{ verticalAlign: 'top', marginLeft: 5 }}
									/>
								</Row>
							</Col>
						</Row>
					);
				}

				return UploadComponent();
			},
		};
	};

	return (
		<Layout selectedKey="templates" breadCrumbs={['App', 'Templates']}>
			{query.loading ? (
				<Loader />
			) : (
				<>
					<Modal
						footer={[]}
						visible={!!description}
						onCancel={() => setDescription(undefined)}
					>
						<div dangerouslySetInnerHTML={{ __html: description || '' }} />
					</Modal>
					<Row justify="end" style={{ paddingBottom: 15 }}>
						<Col flex="auto"></Col>
						<Col>
							<Button type="primary">
								<Link to="/template/create">New Template</Link>
							</Button>
						</Col>
					</Row>
					<Table
						columns={[
							{
								title: 'Image',
								dataIndex: ['metadata', 'screenshot', 'desktop'],
								key: 'image',
								render: (screenshot) => {
									if (!screenshot) return null;

									const src = `${HOSTING_PATH[screenshot.hosting]}/${
										screenshot.filepath
									}`;
									return <Image src={src} alt="screenshot" width={100} />;
								},
							},
							{
								title: 'Name',
								dataIndex: 'name',
								key: 'name',
								...getSearchableColumn('name'),
							},
							...columns,
							descriptionColumn,
							imageColumn('hover'),
							imageColumn('cover'),
							{
								title: 'Created on',
								dataIndex: 'createdAt',
								key: 'createdAt',
								sortOrder: 'descend',
								render: (createdAt) => {
									return moment(createdAt).format('MMMM Do YYYY');
								},
								sorter: (a, b) =>
									moment(a.createdAt).unix() - moment(b.createdAt).unix(),
							},
							{
								title: '',
								key: 'actions',
								render: (_, row) => (
									<>
										<Tooltip title={'Edit'} color="#108ee9">
											<Link to={`/template/${row.id}/edit`}>
												<Button
													icon={<EditOutlined />}
													style={{
														verticalAlign: 'top',
														marginLeft: 5,
														marginBottom: 5,
													}}
												/>
											</Link>
										</Tooltip>
									</>
								),
							},
						]}
						dataSource={query.result?.templates}
					/>
				</>
			)}
		</Layout>
	);
};

export default TemplateList;
