import { RouteComponentProps } from 'react-router';
import { Button, Form } from 'antd';
import { useState } from 'react';
import Layout from '../../components/Layout';
import { Loader } from '../../components/Loader';

import { useApi, QUERY, updateOrganization } from '../../api';
import { Organization as IOrganization } from '../../types';
import { Organization } from '../../components/Organization';

interface MatchParams {
	organizationId: string;
}
interface Props extends RouteComponentProps<MatchParams> {}

export const UpdateOrganization = (props: Props) => {
	const { organizationId } = props.match.params;
	const [unlimited, setUnlimited] = useState<{ [key: string]: boolean }>({
		pageviews: false,
		teams: false,
		seats: false,
		stories: false,
	});

	const [query] = useApi<{ organization?: IOrganization }>({
		query: QUERY.ORGANIZATION,
		variables: {
			id: organizationId,
		},
		timeout: 1000,
		onFinish: (query) => {
			setUnlimited({
				pageviews: !query.organization?.plan?.maxPageviews,
				teams: !query.organization?.plan?.maxTeams,
				seats: !query.organization?.plan?.memberSeats,
				stories: !query.organization?.plan?.maxStories,
			});
		},
	});

	const onFinish = (values: any) => {
		if (unlimited.pageviews) {
			values.plan.maxPageviews = null;
		}

		if (unlimited.seats) {
			values.plan.memberSeats = null;
		}

		if (unlimited.stories) {
			values.plan.maxStories = null;
		}

		if (unlimited.teams) {
			values.plan.maxTeams = null;
		}

		console.log('Success:', values);
		updateOrganization({
			...values,
			id: organizationId,
		});
	};

	const onFail = (values: any) => {
		console.log('Fail:', values);
	};

	return (
		<Layout
			selectedKey="organizations"
			breadCrumbs={[
				'App',
				'Organizations',
				query.result?.organization?.name || 'Loading...',
				'Edit',
			]}
		>
			{query.loading ? (
				<Loader />
			) : (
				<Form
					name="basic"
					wrapperCol={{ span: 6 }}
					initialValues={query.result?.organization}
					onFinish={onFinish}
					onFinishFailed={onFail}
					autoComplete="off"
				>
					<Organization unlimited={unlimited} setUnlimited={setUnlimited} />

					<Form.Item style={{ marginTop: 5 }}>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
					</Form.Item>
				</Form>
			)}
		</Layout>
	);
};
