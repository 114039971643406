import { Row, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const Loader = () => {
	return (
		<Row style={{ paddingTop: 15 }}>
			<Col offset={12}>
				<Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
			</Col>
		</Row>
	);
};
